import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
const store = new Vuex.Store({
  strict: debug,
});

import user from './modules/user';
user(store);
import users from './modules/users';
users(store);
import importTemplates from './modules/importTemplates';
importTemplates(store);
import planningDefaults from './modules/planningDefaults';
planningDefaults(store);
import block from './modules/block';
block(store);
import blocks from './modules/blocks';
blocks(store);
import duels from './modules/duels'
duels(store)
import events from './modules/events';
events(store);
import clubs from './modules/clubs';
clubs(store);
import clubSchedule from './modules/clubSchedule'
clubSchedule(store)
import members from './modules/members';
members(store);
import groups from './modules/groups';
groups(store);
import teams from './modules/teams';
teams(store);
import disciplines from './modules/disciplines';
disciplines(store);
import eventDiscipline from './modules/eventDiscipline';
eventDiscipline(store);
import eventDisciplines from './modules/eventDisciplines';
eventDisciplines(store);
import categories from './modules/categories';
categories(store);
import rankings from './modules/rankings';
rankings(store);
import sessions from './modules/sessions';
sessions(store);
import panels from './modules/panels';
panels(store);
import exercise from './modules/exercise';
exercise(store);
import exercises from './modules/exercises';
exercises(store);
import participations from './modules/participations';
participations(store);
import blockParticipations from './modules/blockParticipations';
blockParticipations(store);
import displays from './modules/displays';
displays(store);
import displayImages from './modules/displayImages';
displayImages(store);
import displayTemplates from './modules/displayTemplates';
displayTemplates(store);
import environment from './modules/environment';
environment(store);
import importer from './modules/importer';
importer(store);
import imports from './modules/imports';
imports(store);

export default store;
