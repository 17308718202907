import Vue from 'vue'
import config from 'client/config'
import socket from 'client/socket'
import filter from 'lodash/filter'

export default function(store) {
  const state = {
    items: [],
    loaded: false,
    sessionId: undefined,
  }

  const actions = {
    "duels.get": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/sessions/' + opts.sessionId + '/duels/').then((result) => {
          store.commit('duels.setData', result.data)
          store.commit('duels.sessionId', opts.sessionId)
          socket.emit('join', 'duel:' + opts.sessionId)
          resolve(result)
        }, err => reject(err))
      })
    },
    "duel.save": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/duels/' + opts.data.id, opts.data).then(result => {
          store.commit('duel.update', result.data)
          resolve(result.data)
        }, err => reject(err))
      })
    },
    "duel.add": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/sessions/' + ctx.state.sessionId + '/duels', opts.data).then(result => {
          store.commit('duel.update', result.data)
          resolve(result)
        }, err => reject(err))
      })
    },
    "duel.remove": function(state, opts) {
      store.commit('duel.remove', opts.duelId)
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/duels/' + opts.duelId).then(() => {
          resolve()
        }, err => reject(err))
      })
    }
  };

  const mutations = {
    "duels.setData": function(state, data) {
      Vue.set(state, 'items', data)
      Vue.set(state, 'loaded', true)
    },
    "duels.sessionId": function(state, sessionId) {
      Vue.set(state, 'sessionId', sessionId)
    },
    "duel.add": function(state, duel) {
      state.items.push(duel)
    },
    "duel.update": function(state, duel) {
      const index = state.items.findIndex(i => i.id === duel.id)
      let duels = state.items
      duels.splice(index, 1, duel)
      Vue.set(state, 'items', duels)
    },
    "duel.remove": function(state, id) {
      const items = filter(state.items, item => item.id !== id)
      Vue.set(state, 'items', items)
    }
  };

  function leaveRooms() {
    if (state.sessionId) {
      socket.emit('leave', 'duel:' + state.sessionId)
    }
  }

  function listener() {
    socket.on('duel.update', function(result) {
      if (result.type === "remove") {
        //remove not supported
        return
      }

      if (result.type === 'update') {
        store.commit('duel.update', result.data)
        return
      }
      if (result.type === 'create') {
        store.commit('duel.add', result.data)
      }
    });
    socket.on('duel.delete', result => {
      store.commit('duel.remove', result.data);
    })
    socket.emit('join', 'duel.delete')
  }

  store.registerModule('duels', {
    state: state,
    mutations: mutations,
    actions: actions
  })

  listener()
}

